import { Box } from '@mui/system'
import { withTranslation } from 'react-i18next'
import { Button, Typography } from '@mui/material'
import DatePicker from './UI/DatePicker'
const dateButtonStyle = {
   border: '2px solid #8F8F8F',
   width: 75,
   height: 28,
   padding: '2px',
   borderRadius: 30,
   bgcolor: '#F5F5F5',
   color: '#8F8F8F',
   '&:hover': {
      bgcolor: '#2D3A73',
      color: '#fff',
      border: '2px solid #2D3A73',
   },
}
const textButtonStyle = { fontSize: '13px', mt: '2px', fontWeight: 500 }

const buttonMonthAction = (selectedPeriod, months) => {
   const endDate = new Date(selectedPeriod.endDate)
   const newStartDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth() - months,
      endDate.getDate()
   )
   return newStartDate
}
const periodButtons = [
   {
      buttonText: '1week',
      buttonAction: selectedPeriod => {
         const endDate = new Date(selectedPeriod.endDate)
         const newStartDate = new Date(endDate.getTime() - 7 * 24 * 60 * 60 * 1000)
         return newStartDate
      },
   },
   {
      buttonText: '1month',
      buttonAction: selectedPeriod => {
         return buttonMonthAction(selectedPeriod, 1)
      },
   },
   {
      buttonText: '3month',
      buttonAction: selectedPeriod => {
         return buttonMonthAction(selectedPeriod, 3)
      },
   },
   {
      buttonText: '6month',
      buttonAction: selectedPeriod => {
         return buttonMonthAction(selectedPeriod, 6)
      },
   },
   {
      buttonText: '1year',
      buttonAction: selectedPeriod => {
         const endDate = new Date(selectedPeriod.endDate)
         const newStartDate = new Date(
            endDate.getFullYear() - 1,
            endDate.getMonth(),
            endDate.getDate()
         )
         return newStartDate
      },
   },
]
const DateManipulator = ({ selectedPeriod, setSelectedPeriod, t }) => {
   const handleEndDate = newDate => {
      setSelectedPeriod(prev => ({
         ...prev,
         endDate: new Date(newDate),
      }))
   }
   const handleStartDate = newDate => {
      setSelectedPeriod(prev => ({
         ...prev,
         startDate: new Date(newDate),
      }))
   }
   const handleButtonAction = buttonAction => {
      setSelectedPeriod(prev => ({ ...prev, startDate: buttonAction(selectedPeriod) }))
   }
   return (
      <Box>
         <Box
            sx={{
               display: 'flex',
               height: '40px',
               width: 420,
               justifyContent: 'space-between',
               position: 'relative',
            }}
         >
            <DatePicker
               selectedPeriod={selectedPeriod.startDate}
               hover={1}
               handleDate={handleStartDate}
               max={selectedPeriod.endDate}
            />
            <DatePicker
               selectedPeriod={selectedPeriod.endDate}
               hover={2}
               handleDate={handleEndDate}
               min={selectedPeriod.startDate}
            />
         </Box>

         <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
            {periodButtons.map(({ buttonText, buttonAction }) => {
               return (
                  <Button
                     key={buttonText}
                     selected
                     sx={dateButtonStyle}
                     onClick={() => handleButtonAction(buttonAction)}
                  >
                     <Typography sx={textButtonStyle}>{t(buttonText)}</Typography>
                  </Button>
               )
            })}
         </Box>
      </Box>
   )
}

export default withTranslation()(DateManipulator)
