import React from 'react'
import { Typography, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { withTranslation } from 'react-i18next'
import {
   EmojiPeople as EmojiPeopleIcon,
   ChildFriendly as ChildFriendlyIcon,
} from '@mui/icons-material'
import workDayIcon from '../../../../icon/Frame 59.svg'

const useStyles = makeStyles({
   childIcon: {
      color: '#FF8038',
   },
   noProject: {
      color: '#489D74',
   },
})

const TimesheetManagementProjectIcon = React.memo(({ person, calculateWorkTime }) => {
   const classes = useStyles()

   function numberToTime(num) {
      const hours = Math.floor(num)
      const minutes = Math.round((num - hours) * 60)
      return `${hours}:${String(minutes).padStart(2, '0')}`
   }

   function timeToNumber(time) {
      const [hours, minutes] = time.split(':')
      return Number(hours) + Number(minutes) / 60
   }
   const renderPersonIcon = () => {
      if (person?.ParentalLeave) {
         return <ChildFriendlyIcon className={classes.childIcon} />
      } else if (person?.projectReports.length) {
         return availableTime()
      } else {
         return <EmojiPeopleIcon className={classes.noProject} />
      }
   }

   const availableTime = () => {
      let percent = 0
      person.projectReports.forEach(project => {
         if (project.workPercent) {
            percent += Number(project.workPercent)
         }
      })
      const percentInTime = calculateWorkTime(percent)
      const time = timeToNumber(percentInTime)
      const availableTime = 8.0 - time
      const availableTimeInHoursAndMinutes = numberToTime(availableTime)
      if (percent >= 100) {
         return <img src={workDayIcon} alt="Work day icon" />
      } else {
         return (
            <Box
               sx={{
                  width: '72px',
                  height: '18px',

                  p: '2px 6px 2px 6px',
                  gap: '10px',
                  borderRadius: '4px',
                  backgroundColor:
                     percent <= 20
                        ? '#59A546'
                        : percent >= 20 && percent <= 70
                        ? '#59A546CC'
                        : '#9BE58C',
               }}
            >
               <Typography
                  sx={{
                     color: '#fff',
                     fontFamily: 'Roboto',
                     fontSize: '12px',
                     fontWeight: 600,
                     lineHeight: '15px',
                     letterSpacing: '0em',
                     textAlign: 'center',
                  }}
               >
                  {availableTimeInHoursAndMinutes} AVBL
               </Typography>
            </Box>
         )
      }
   }
   return <>{renderPersonIcon()}</>
})

export default withTranslation()(TimesheetManagementProjectIcon)
