import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import React from 'react'
import TimesheetManagementTableRow from '../../../UI/TimesheetManagementTableRow'

const TimesheetManagementRows = React.memo(
   ({
      t,
      pageData,
      filteredUsers,
      chosenDateWeek,
      getRelevantColourClass,
      reportDay,
      openModal,
      isHaveReports,
      calculateProjectActualHours,
      calculateProjectExpectedHours,
      isTodayAfterLastWeek,
      calculateWorkTime,
      newDataFetching,
   }) => {
      const props = {
         t,
         pageData,
         filteredUsers,
         chosenDateWeek,
         getRelevantColourClass,
         reportDay,
         openModal,
         isHaveReports,
         calculateProjectActualHours,
         calculateProjectExpectedHours,
         isTodayAfterLastWeek,
         calculateWorkTime,
         newDataFetching,
      }
      return <TimesheetManagementTableRow {...props} />
   }
)
const mapStateToProps = state => {
   return {
      state,
   }
}
export default withTranslation()(connect(mapStateToProps)(TimesheetManagementRows))
