import { withTranslation } from 'react-i18next'
import TimesheetManagementProjectIcon from '../table/dataRows/components/TimesheetManagementProjectIcon'
import externalProjectIcon from '../../icon/Polygon 2.svg'
import clsx from 'clsx'
import { Typography, Box, TableCell, TableRow, Button, Skeleton } from '@mui/material'
import React, { Fragment, useState } from 'react'
import { withStyles } from '@mui/styles'
import makeStyles from '@mui/styles/makeStyles'
import { sortByProjectType } from '../../../../util/helpers'

const useStyles = makeStyles({
   tableCellItems: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
   },
   noBorder: {
      border: 'none',
      boxShadow: 'none',
      zIndex: 0,
   },
   tableRow: {
      '& td:not(:first-child):not(:last-child):not(:nth-child(10))': {
         backgroundColor: '#F8F8F8',
      },
   },
   projectCrossHover: {
      '&:hover': {
         backgroundColor: 'transparent',
      },
      '&:hover td:not(:nth-child(1)):not(:nth-child(10)):not(:last-child)': {
         backgroundColor: '#EDF6FA',
      },
      '&:hover td:nth-child(1)': {
         backgroundColor: '#EDF6FA',
         borderLeft: '2px solid #D0EBF6',
      },
   },
   personCrossHover: {
      '&:hover': {
         backgroundColor: 'transparent',
      },
      '&:hover > *:not(:nth-child(1)):not(:nth-child(10))': {
         backgroundColor: '#EDF6FA !important',
      },
      '&:hover > *:nth-child(1)': {
         backgroundColor: '#EDF6FA !important',
         borderLeft: '3px solid #D0EBF6',
      },
   },
   myTableRow: {
      '& td:first-child': {
         borderLeft: '2px solid transparent',
         borderTopLeftRadius: '4px',
         borderBottomLeftRadius: '4px',
         backgroundColor: '#F8F8F8',
         marginLeft: 8,
      },
   },

   personAndProjectsHover: {
      '& td:first-child': {
         borderLeft: '2px solid transparent',
         borderTopLeftRadius: '4px',
         borderBottomLeftRadius: '4px',
         backgroundColor: '#EDF6FA',
         marginLeft: 8,
      },
      '& td:not(:first-child):not(:last-child):not(:nth-child(10))': {
         backgroundColor: '#EDF6FA',
      },
   },

   myParentTableRow: {
      '& td:first-child': {
         borderLeft: '3px solid transparent',
         borderTopLeftRadius: '4px',
         borderBottomLeftRadius: '4px',
      },
   },

   weekIndicator: {
      backgroundColor: '#fff',
      color: 'black',
      padding: 2,
      maxWidth: 60,
      minWidth: 60,
   },
})
const StyledButton = withStyles({
   root: {
      backgroundColor: '#fff',
      color: '#008DC6',
      '&:hover': {
         backgroundColor: '#008DC6',
         color: '#fff',
      },
   },
})(Button)

const TimesheetManagementTableRow = ({
   t,
   pageData,
   filteredUsers,
   chosenDateWeek,
   getRelevantColourClass,
   reportDay,
   openModal,
   isHaveReports,
   calculateProjectActualHours,
   calculateProjectExpectedHours,
   isTodayAfterLastWeek,
   calculateWorkTime,
   newDataFetching,
}) => {
   const [hoveredPersonId, setHoveredPersonId] = useState(null)

   const classes = useStyles()
   return (
      <>
         {pageData?.map(person => (
            <Fragment key={person._id}>
               <TableRow
                  className={clsx(classes.personCrossHover, classes.myParentTableRow)}
                  key={person._id}
                  onMouseEnter={() => setHoveredPersonId(person._id)}
                  onMouseLeave={() => setHoveredPersonId(null)}
               >
                  <TableCell
                     className={(clsx(classes.noBorder), classes.tableCellItems)}
                     sx={{ minWidth: 420, padding: '16px 2px 4px 2px' }}
                  >
                     {person.FirstName + ' ' + person.LastName}

                     <div className={classes.tableCellItems}>
                        {
                           <TimesheetManagementProjectIcon
                              person={person}
                              filteredUsers={filteredUsers}
                              calculateWorkTime={calculateWorkTime}
                           />
                        }
                     </div>
                  </TableCell>

                  <TableCell
                     style={{
                        backgroundColor: 'transparent',
                        color: '#bbaaee',
                        padding: 2,
                        maxWidth: 60,
                        minWidth: 60,
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                     }}
                  ></TableCell>
                  <TableCell colSpan={7} align={'center'}></TableCell>

                  <TableCell
                     align={'center'}
                     style={{
                        backgroundColor: 'transparent',
                        color: '#bbaaee',
                        padding: 2,
                        maxWidth: 60,
                        minWidth: 60,
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                     }}
                  >
                     <StyledButton
                        style={{
                           position: 'inherit',
                           display: isHaveReports(person, chosenDateWeek.weeksSorted[0])
                              ? ''
                              : 'none',
                        }}
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => openModal(person, chosenDateWeek.weeksSorted[0])}
                     >
                        {t('viewWeek')}
                     </StyledButton>
                  </TableCell>
                  <TableCell colSpan={7} align={'center'}></TableCell>
                  <TableCell
                     align={'center'}
                     style={{
                        backgroundColor: 'transparent',
                        color: '#bbaaee',
                        padding: 2,
                        maxWidth: 60,
                        minWidth: 60,
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                     }}
                  >
                     <StyledButton
                        style={{
                           position: 'inherit',
                           display: isHaveReports(person, chosenDateWeek.weeksSorted[1])
                              ? ''
                              : 'none',
                        }}
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => openModal(person, chosenDateWeek.weeksSorted[1])}
                     >
                        {t('viewWeek')}
                     </StyledButton>
                  </TableCell>
               </TableRow>

               {person.projectReports.sort(sortByProjectType).map(project =>
                  newDataFetching ? (
                     <TableRow>
                        <TableCell colSpan={19} sx={{ height: 31.5, borderBottom: 'none' }}>
                           <Skeleton sx={{ height: '260%', borderRadius: '4px' }} />
                        </TableCell>
                     </TableRow>
                  ) : (
                     <TableRow
                        key={project._id}
                        className={clsx(
                           hoveredPersonId === project.userId
                              ? classes.personAndProjectsHover
                              : classes.projectCrossHover,
                           classes.myTableRow,
                           classes.tableRow
                        )}
                     >
                        <TableCell
                           className={(clsx(classes.noBorder), classes.tableCellItems)}
                           sx={{
                              minWidth: 200,
                              border: 'none',
                              p: '6px 8px',
                           }}
                        >
                           <Box
                              sx={{
                                 display: 'flex',
                                 alignItems: 'baseline',
                              }}
                           >
                              {project.projectType === 'External' ? (
                                 <img
                                    style={{ marginRight: 3 }}
                                    src={externalProjectIcon}
                                    alt="Project icon"
                                 />
                              ) : (
                                 <Box sx={{ marginRight: 2 }} />
                              )}
                              <Typography sx={{ fontWeight: 600, fontSize: 13 }}>
                                 {project.name}
                              </Typography>
                           </Box>
                           <div className={classes.tableCellItems}>
                              <Typography
                                 sx={{
                                    color: '#0198CF',
                                    fontFamily: 'Roboto',
                                    fontSize: 14,
                                    fontWeight: 700,
                                    lineHeight: '16px',
                                    letterSpacing: '0em',
                                    textAlign: 'left',
                                    ml: '5px',
                                 }}
                              >
                                 {`${calculateWorkTime(project.workPercent)}h/${
                                    project.workPercent ?? 0
                                 }%`}
                              </Typography>
                           </div>
                        </TableCell>
                        <TableCell
                           style={{
                              padding: 2,
                              maxWidth: 60,
                              minWidth: 60,
                              border: 'none',
                           }}
                        />
                        {chosenDateWeek?.weekDates.map((date, index) => {
                           return (
                              <Fragment key={date}>
                                 <TableCell
                                    align={'center'}
                                    style={{
                                       padding: 1,
                                       maxWidth: 24,
                                    }}
                                    className={clsx(classes.white, getRelevantColourClass(date))}
                                 >
                                    {reportDay(date, project, person?.absences)}
                                 </TableCell>
                                 {index === 6 && (
                                    <TableCell
                                       align={'center'}
                                       className={clsx(classes.weekIndicator, classes.noBorder)}
                                       style={{ minWidth: 20 }}
                                    >
                                       <Box
                                          sx={{
                                             display: 'flex',
                                             justifyContent: 'space-evenly',
                                          }}
                                       >
                                          <Typography>
                                             {calculateProjectActualHours(
                                                project.reportedDates,
                                                chosenDateWeek.weeksSorted,
                                                0
                                             )}
                                             {t('h')}
                                          </Typography>
                                          {isTodayAfterLastWeek(
                                             chosenDateWeek.weeksSorted[0].dates
                                          ) && <Box sx={{ borderLeft: '1px solid #D3D3D3' }} />}
                                          {isTodayAfterLastWeek(
                                             chosenDateWeek.weeksSorted[0].dates
                                          ) && (
                                             <Box>
                                                {calculateProjectExpectedHours(
                                                   project.workPercent,
                                                   chosenDateWeek.weeksSorted,
                                                   0,
                                                   person.absences,
                                                   calculateProjectActualHours(
                                                      project.reportedDates,
                                                      chosenDateWeek.weeksSorted,
                                                      0
                                                   ),
                                                   project.endDate
                                                )}
                                             </Box>
                                          )}
                                       </Box>
                                    </TableCell>
                                 )}
                              </Fragment>
                           )
                        })}
                        <TableCell
                           align={'center'}
                           className={clsx(classes.weekIndicator, classes.noBorder)}
                           style={{ minWidth: 20, padding: 0 }}
                        >
                           <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                              <Typography>
                                 {calculateProjectActualHours(
                                    project.reportedDates,
                                    chosenDateWeek.weeksSorted,
                                    1
                                 )}
                                 {t('h')}
                              </Typography>
                              {isTodayAfterLastWeek(chosenDateWeek.weeksSorted[1].dates) && (
                                 <Box sx={{ borderLeft: '1px solid #D3D3D3' }} />
                              )}
                              {isTodayAfterLastWeek(chosenDateWeek.weeksSorted[1].dates) && (
                                 <Box>
                                    {calculateProjectExpectedHours(
                                       project.workPercent,
                                       chosenDateWeek.weeksSorted,
                                       1,
                                       person.absences,
                                       calculateProjectActualHours(
                                          project.reportedDates,
                                          chosenDateWeek.weeksSorted,
                                          1
                                       ),
                                       project.endDate
                                    )}
                                 </Box>
                              )}
                           </Box>
                        </TableCell>
                     </TableRow>
                  )
               )}
            </Fragment>
         ))}
      </>
   )
}
export default withTranslation()(TimesheetManagementTableRow)
