import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Snackbar from '@mui/material/Snackbar'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import React, { useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropdown/style.css'
import { connect } from 'react-redux'
import ConfirmDialog from '../ConfirmDialog'
import themeConfig from '../theme'
import {
   newProjectReport,
   fetchProjectReports,
   deleteProjectReport,
   fetchCustomerForAdmin,
   getSkillsForCustomer,
} from '../Api'
import Checkbox from '@mui/material/Checkbox'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Moment from 'react-moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faFolder } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import RotateLoader from 'react-spinners/RotateLoader'
import { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-GB'
import bg from 'date-fns/locale/bg'
import log from '../util/log'
import { withTranslation } from 'react-i18next'
import { MenuItem, OutlinedInput, Select, TextField } from '@mui/material'
import { Autocomplete } from '@mui/material'
registerLocale('sv', sv)
registerLocale('en-GB', en)
registerLocale('bg', bg)

const { styles } = themeConfig()

const useStyles = makeStyles(theme => ({
   datepickerField: {
      width: '30%',
      '& .react-datepicker-wrapper': {
         width: '100%',
      },
   },
   select: {
      marginTop: '0.25rem',
      width: '100%',
      height: '40px',
      fontSize: '0.875rem',
      borderRadius: '4px',
      '& .MuiSelect-icon': {
         right: '10px',
      },
   },
   autocomplete: {
      display: 'flex',
      flexWrap: 'wrap',
      '& .MuiAutocomplete-inputRoot': {
         borderRadius: '4px',
         marginTop: '0.25rem',
         fontSize: '14px',
         boxSizing: 'border-box',
         padding: '2px 6px',
         flexGrow: 1,
         border: 0,
         margin: 0,
         outline: 0,
      },
   },
}))

const getStyles = (role, filterByRole, theme) => {
   return {
      fontWeight:
         filterByRole.indexOf(role) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
   }
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
   PaperProps: {
      style: {
         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
         width: 250,
      },
   },
}

const roles = [
   'Frontend Developer',
   'Backend Developer',
   'Full Stack Developer',
   'Technical Lead',
   'Java Developer',
   'Hybris Developer',
   'QA Specialist',
   'Product Owner',
   'Business Analyst',
   'Project Manager',
   'UX/UI Designer',
   'Cloud Architect',
   'Solutions Architect',
   'ByD Consultant',
]

const ProjectReports = ({ ...props }) => {
   const classes = useStyles()
   const theme = useTheme()
   const { userInfo, t } = props
   const [snackbarState, setSnackbarState] = React.useState({
      open: false,
      message: '',
      severity: 'info',
   })

   const [startDate, setStartDate] = React.useState(null)
   const [endDate, setEndDate] = React.useState(null)
   const [includeInternalProjects, setIncludeInternalProjects] = React.useState(false)
   const [projectReports, setProjectReports] = React.useState([])
   const [isLoading, setIsLoading] = React.useState(true)
   const [filterByRole, setFilterByRole] = React.useState([])
   const [initialSkills, setInitialSkills] = React.useState([])
   const [selectedSkills, setSelectedSkills] = React.useState([])

   const [confirmState, setConfirmState] = React.useState({
      confirmButtonTitle: null,
      showConfirm: false,
      confirmMessage: null,
      confirmOnChange: null,
      confirmButtonVariant: null,
      confirmTitle: null,
   })

   const handleChange = event => {
      const {
         target: { value },
      } = event
      setFilterByRole(typeof value === 'string' ? value.split(',') : value)
   }

   const closeSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
         return
      }
      setSnackbarState({ open: false })
   }

   const toggleConfirm = (show, title, message, buttonTitle, buttonVariant, onChange) => {
      setConfirmState({
         showConfirm: show,
         confirmTitle: title,
         confirmMessage: message,
         confirmButtonTitle: buttonTitle,
         confirmButtonVariant: buttonVariant,
         confirmOnChange: onChange,
      })
   }

   useEffect(() => {
      const fetchData = async () => {
         setIsLoading(true)

         try {
            const data = await fetchProjectReports(userInfo)
            setProjectReports(data.reports)
         } catch (error) {
            log.error(error)
         }

         setIsLoading(false)
      }

      fetchData()
      // eslint-disable-next-line
   }, [fetchProjectReports])

   useEffect(() => {
      const fillSkills = async () => {
         try {
            const result = await fetchCustomerForAdmin(userInfo)
            let customer = result.data
            if (customer._id) {
               const result = await getSkillsForCustomer(customer._id, userInfo)
               setInitialSkills(result.data)
            }
         } catch (error) {
            log.error(error)
         }
      }
      fillSkills()
   }, [userInfo])

   const handleNew = async () => {
      setIsLoading(true)
      const user = props.users.find(u => u.EmployeeID === props.userInfo.EmployeeID)

      const data = {
         EmployeeID: props.userInfo.EmployeeID,
         CreatedByName:
            user.FirstName + ' ' + (user.MiddleName ? user.MiddleName + ' ' : '') + user.LastName,
         StartDate: startDate,
         EndDate: endDate,
         IncludeInternalProjects: includeInternalProjects,
         FilterByRole: filterByRole,
         FilterBySkill: selectedSkills.length ? selectedSkills.map(skill => skill.Name) : [],
      }

      const result = await newProjectReport(props.userInfo, data)
      log.debug('handleNew result: ' + JSON.stringify(result))

      if (result && result.data.success) {
         setSnackbarState({ open: true, message: t('newReportMsg'), severity: 'success' })
         // Reload reports
         const data = await fetchProjectReports(props.userInfo)
         setProjectReports(data.reports)
      } else {
         setSnackbarState({ open: true, message: result.data.error, severity: 'error' })
      }
      setIsLoading(false)
   }

   const deleteReport = async spreadsheetId => {
      setIsLoading(true)
      const result = await deleteProjectReport(props.userInfo, spreadsheetId)
      if (result && result.data.success) {
         setSnackbarState({ open: true, message: t('reportDeletedMsg'), severity: 'success' })
         // Reload reports
         const data = await fetchProjectReports(props.userInfo)
         setProjectReports(data.reports)
      } else {
         setSnackbarState({ open: true, message: result.data.error, severity: 'error' })
      }
      setIsLoading(false)
   }

   if (props.users == null) {
      return t('loadingData')
   }

   return (
      <React.Fragment>
         <ConfirmDialog
            {...{
               confirmTitle: confirmState.confirmTitle,
               confirmButtonTitle: confirmState.confirmButtonTitle,
               confirmButtonVariant: confirmState.confirmButtonVariant,
               showConfirm: confirmState.showConfirm,
               message: confirmState.confirmMessage,
               handleConfirm: confirmState.confirmOnChange,
               toggleConfirm: toggleConfirm,
            }}
         />
         <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={closeSnackbar}>
            <Alert onClose={closeSnackbar} severity={snackbarState.severity}>
               {snackbarState.message}
            </Alert>
         </Snackbar>
         <Paper
            className="contentPaper"
            style={{ maxWidth: 1200, padding: '10px 20px', boxShadow: 'none' }}
         >
            <div className={'contentWrapper'}>
               <div style={{ padding: 10 }}>
                  <Typography variant="h5" style={{ fontSize: '22px' }}>
                     {t('createProjectReportTitle')}
                  </Typography>
               </div>
               <div style={{ padding: '10px 0 5px 10px' }}>
                  <Typography style={{ fontSize: '1.1rem', fontWeight: '500' }}>
                     {t('selectPeriod')}
                  </Typography>
               </div>
               <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ padding: 10 }} className={classes.datepickerField}>
                     <Typography variant="body2">{t('startDate')}</Typography>
                     <DatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        dateFormat="dd.MM.yyyy"
                        locale={t('datepickerLocale')}
                        className={'datepicker-field mt-1'}
                        popperPlacement="top"
                     />
                  </div>
                  <div style={{ padding: 10 }} className={classes.datepickerField}>
                     <Typography variant="body2">{t('endDate')}</Typography>
                     <DatePicker
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        dateFormat="dd.MM.yyyy"
                        locale={t('datepickerLocale')}
                        className={'datepicker-field mt-1'}
                        popperPlacement="top"
                        disabled={!startDate}
                     />
                  </div>
               </div>
               <div style={{ padding: '20px 0 5px 10px' }}>
                  <Typography style={{ fontSize: '1.1rem', fontWeight: '500' }}>
                     {t('applyFilters')}
                  </Typography>
               </div>
               <div
                  style={{
                     display: 'flex',
                     flexDirection: 'row',
                     alignItems: 'start',
                     padding: 10,
                  }}
               >
                  <div style={{ width: '29%' }}>
                     <Typography variant="body2">{t('selectProjectRoles')}</Typography>
                     <Select
                        className={classes.select}
                        placeholder={t('select')}
                        multiple
                        value={filterByRole}
                        onChange={handleChange}
                        input={<OutlinedInput />}
                        MenuProps={MenuProps}
                     >
                        {roles.map(role => (
                           <MenuItem
                              key={role}
                              value={role}
                              style={getStyles(role, filterByRole, theme)}
                           >
                              {role}
                           </MenuItem>
                        ))}
                     </Select>
                  </div>
                  <div style={{ width: '30%', height: '40px', paddingLeft: 20 }}>
                     <Typography variant="body2">{t('selectSkills')}</Typography>
                     <Autocomplete
                        multiple
                        id="multiple-limit-tags"
                        options={initialSkills.filter(
                           skill =>
                              !selectedSkills.find(selectedSkill => selectedSkill._id === skill._id)
                        )}
                        value={selectedSkills}
                        getOptionLabel={option => option.Name}
                        disableCloseOnSelect={true}
                        onChange={(e, arrayOfSkills) => setSelectedSkills(arrayOfSkills)}
                        renderInput={params => <TextField {...params} variant="outlined" />}
                        className={classes.autocomplete}
                     />
                  </div>
               </div>
               <div style={{ paddingTop: '10px' }}>
                  <Checkbox
                     checked={includeInternalProjects}
                     onChange={() => setIncludeInternalProjects(!includeInternalProjects)}
                  />
                  {t('includeInternalProjects')}
               </div>
               {endDate !== null && startDate > endDate && (
                  <div style={{ padding: 10 }}>
                     <Alert style={{ padding: 10 }} severity="error">
                        {t('datesCompareError')}
                     </Alert>
                  </div>
               )}
               <div style={{ padding: 10 }}>
                  <div>
                     <Button
                        size="small"
                        disabled={!startDate || !endDate || startDate > endDate}
                        variant="contained"
                        color="primary"
                        onClick={() => handleNew()}
                     >
                        {t('createReport')}
                     </Button>
                  </div>
               </div>
            </div>
         </Paper>

         <Paper
            className="contentPaper"
            style={{ maxWidth: 1200, marginTop: 20, padding: '10px 20px', boxShadow: 'none' }}
         >
            <div className={'contentWrapper'}>
               <div style={{ padding: 10 }}>
                  <Typography variant="h5" style={{ fontSize: '22px' }}>
                     {t('archiveProjectReport')}
                  </Typography>
               </div>

               {isLoading ? (
                  <div style={{ position: 'relative', left: '50%' }}>
                     <RotateLoader color="#009be5" loading={true} size={16} margin={8} />
                  </div>
               ) : projectReports.length ? (
                  <div style={{ padding: 10 }}>
                     <React.Fragment>
                        <TableContainer
                           className="employmentTable"
                           component={Paper}
                           style={{ maxHeight: 500 }}
                        >
                           <Table size="small">
                              <TableHead>
                                 <TableRow>
                                    <TableCell align="center">{t('Title')}</TableCell>
                                    <TableCell align="center">{t('Created')}</TableCell>
                                    <TableCell align="center">{t('From')}</TableCell>
                                    <TableCell align="center">{t('To')}</TableCell>
                                    <TableCell align="center">{t('createdBy')}</TableCell>
                                    <TableCell>{t('Actions')}</TableCell>
                                 </TableRow>
                              </TableHead>

                              <TableBody>
                                 {!isLoading &&
                                    projectReports.map((report, index) => (
                                       <React.Fragment key={index}>
                                          <TableRow
                                             style={
                                                report.webViewLink
                                                   ? { backgroundColor: '#e4ede7' }
                                                   : {}
                                             }
                                          >
                                             <TableCell align="start">{report.name}</TableCell>
                                             <TableCell align="center">
                                                {report.createdTime != null ? (
                                                   <Moment
                                                      date={report.createdTime}
                                                      format="DD.MM.YYYY HH:mm"
                                                   />
                                                ) : (
                                                   ''
                                                )}
                                             </TableCell>
                                             <TableCell align="center">
                                                {report.appProperties.startDate}
                                             </TableCell>
                                             <TableCell align="center">
                                                {report.appProperties.endDate}
                                             </TableCell>
                                             <TableCell align="center">
                                                {report.appProperties.createdByName}
                                             </TableCell>
                                             <TableCell>
                                                {report.id && (
                                                   <FontAwesomeIcon
                                                      style={{ marginRight: 6 }}
                                                      icon={faTrash}
                                                      onClick={() =>
                                                         toggleConfirm(
                                                            true,
                                                            t('deleteReport'),
                                                            t('deleteReportName') +
                                                               report.name +
                                                               '?',
                                                            t('deleteReport'),
                                                            'danger',
                                                            () => deleteReport(report.id)
                                                         )
                                                      }
                                                   />
                                                )}

                                                {report.id && (
                                                   <>
                                                      <Link
                                                         target={'_blank'}
                                                         to={{
                                                            pathname:
                                                               report.appProperties.folderUrl,
                                                         }}
                                                      >
                                                         <FontAwesomeIcon
                                                            style={{ marginRight: 6 }}
                                                            icon={faFolder}
                                                         />
                                                      </Link>

                                                      <Link
                                                         target={'_blank'}
                                                         to={{ pathname: report.webViewLink }}
                                                      >
                                                         <FontAwesomeIcon
                                                            style={{ marginRight: 6 }}
                                                            icon={faFile}
                                                         />
                                                      </Link>
                                                   </>
                                                )}
                                             </TableCell>
                                          </TableRow>
                                       </React.Fragment>
                                    ))}
                              </TableBody>
                           </Table>
                        </TableContainer>
                     </React.Fragment>
                  </div>
               ) : (
                  <h5 style={{ padding: 10 }} className={'mt-2'}>
                     {t('noResultsFound')}
                  </h5>
               )}
            </div>
         </Paper>
      </React.Fragment>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      loggedIn: state.loggedIn,
      users: state.users,
      workDay: state.workDay,
   }
}

export default withStyles(styles)(withTranslation()(connect(mapStateToProps)(ProjectReports)))
