import React, { useState, useEffect } from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Link from '@mui/material/Link'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { authenticate, logout, setTimeZone } from './redux/actions/actions'
import log from './util/log'
import { withTranslation } from 'react-i18next'
import { updateBackendWithToken } from './Api.js'
import { BACKEND_URL } from './util/dependencies'
import jwt_decode from 'jwt-decode'

function Copyright() {
   return (
      <Typography variant="body2" style={{ color: '#6d7c8c' }} align="center">
         {'Copyright © '}
         <Link color="inherit" href="">
            Novarto Nordic AB
         </Link>{' '}
         {new Date().getFullYear()}
         {'. '}
         <Link color="inherit" href="/privacy_and_TC">
            {'Privacy and T&C'}
         </Link>
      </Typography>
   )
}

const useStyles = makeStyles(theme => ({
   paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
}))

function Login(props) {
   const [errorAlert, setErrorAlert] = useState(false)
   const [errorMsg, setErrorMsg] = useState('')

   const classes = useStyles()

   const google = window.google

   const { t } = props

   useEffect(() => {
      document.body.style = 'background: #FFF;'
   }, [])

   const googleSuccess = async response => {
      try {
         log.debug('GOOGLE SUCCESS:')

         const userData = jwt_decode(response.credential)

         await fetch(BACKEND_URL + '/auth/isValidEmail?email=' + userData.email)
            .then(response => response.json())
            .then(async data => {
               log.debug('isValidEmail: ' + JSON.stringify(data))

               if (data.valid) {
                  try {
                     const verificationResponse = await updateBackendWithToken(
                        userData.email,
                        'google',
                        response.credential
                     )

                     if (verificationResponse.data.success) {
                        let settings = data.Settings
                        if (!settings) {
                           settings = {
                              ColourScheme: 'Traffic Light',
                              IconScheme: 'Dots Default',
                              StripedOverview: true,
                              GroupedOverview: false,
                              ShowDayNames: true,
                              ShowWeekTop: true,
                              NumberOfWeeksShown: 2,
                              PushExercise: true,
                              TeamSelect: null,
                           }
                        }

                        let reports = data.AcceptedReports
                        if (!reports) {
                           reports = []
                        }

                        let ndas = data.AcceptedNDA
                        if (!ndas) {
                           ndas = []
                        }

                        let LoginAllowed = data.LoginAllowed
                        if (!LoginAllowed) {
                           LoginAllowed = true
                        }

                        let Language = data.Language
                        if (!Language) {
                           Language = 'en'
                        }

                        let TimeZone = data.TimeZone
                        if (!TimeZone) {
                           TimeZone = 'Europe/Amsterdam'
                        }

                        const currentUserPermissions = returnCurrentUserPermissions(data)

                        // update redux store
                        if (LoginAllowed) {
                           localStorage.setItem('novartoHrNewLogin', 'true')
                           props.authenticate({
                              EmployeeID: data.EmployeeID,
                              Settings: settings,
                              AcceptedReports: reports,
                              AcceptedNDA: ndas,
                              googleAccessToken: response.credential,
                              email: userData.email,
                              imageUrl: userData.picture,
                              name: userData.name,
                              manager: data.Manager,
                              backofficeAdmin: data.BackofficeAdmin,
                              admin: data.Admin,
                              superAdmin: data.SuperAdmin,
                              LoginAllowed: LoginAllowed,
                              Language: Language,
                              loginProvider: 'google',
                              permissions: currentUserPermissions,
                           })

                           returnCurrentUserPermissions(data)

                           props.setTimeZone(TimeZone)

                           // Set language to fetched from backend
                           props.i18n.changeLanguage(Language)
                        } else {
                           setErrorMsg(t('loginNotAllowed'))
                           setErrorAlert(true)
                           log.info('User not allowed to login.')
                        }
                     } else {
                        setErrorMsg(t('loginNotAllowed'))
                        setErrorAlert(true)
                        log.info('Not valid email')
                     }
                  } catch {
                     setErrorMsg(t('loginNotAllowed'))
                     setErrorAlert(true)
                     log.info('Not valid email')
                  }
               } else {
                  setErrorMsg(t('loginNotAllowed'))
                  setErrorAlert(true)
                  log.info('Not valid email')
               }
            })
            .catch(error => {
               setErrorMsg('Network error: ' + error)
               setErrorAlert(true)
               log.info('Not valid email')
            })
      } catch {}
   }

   const googleError = response => {
      log.error('GOOGLE ERROR: ')
      log.error(response)

      setErrorMsg(t('googleLoginError') + (response.details ? ' ' + response.details : ''))
      setErrorAlert(true)
   }

   const returnCurrentUserPermissions = user => {
      const roles = {
         manager: user.Manager,
         backofficeAdmin: user.BackofficeAdmin,
         admin: user.Admin,
      }

      let roleFiles = Object.keys(roles)
         .filter(role => roles[role] === true)
         .map(role => {
            role = { [role]: require(`./roles/${role}.json`) }
            return Object.values(role)[0]
         })

      let currentPermissions = {}
      if (roleFiles.length) {
         roleFiles.forEach((roleJson, index) => {
            if (index === 0) {
               currentPermissions = roleJson
            } else {
               Object.keys(roleJson).forEach(component => {
                  Object.keys(roleJson[component]).forEach(permission => {
                     if (
                        currentPermissions[component][permission] ||
                        roleJson[component][permission]
                     ) {
                        currentPermissions[component][permission] = true
                     }
                  })
               })
            }
         })
      } else {
         const userJson = require(`./roles/user.json`)
         currentPermissions = userJson
      }
      return currentPermissions
   }

   return props.loggedIn ? (
      <Redirect to="/" />
   ) : (
      <Container
         style={{
            paddingLeft: 50,
            paddingRight: 50,
            paddingTop: 20,
            paddingBottom: 20,
            marginTop: 50,
            width: 500,
         }}
      >
         <CssBaseline />
         <div className={classes.paper}>
            <img
               style={{ width: 400 }}
               src="/novarto-BlueLetters.png"
               alt="novarto nordic logo"
            ></img>

            <Typography style={{ color: '#6d7c8c', marginTop: 40 }} component="h1" variant="h5">
               Novarto HR
            </Typography>

            <div style={{ marginTop: 20 }}>
               <GoogleLogin
                  onSuccess={googleSuccess}
                  onError={() => googleError()}
                  cookiePolicy={'single_host_origin'}
               />
            </div>

            <div style={{ marginTop: 20 }}>
               <Button variant="outlined" href="/hills/login">
                  Login for Hills
               </Button>
            </div>

            {errorAlert ? (
               <div style={{ marginTop: 20 }}>
                  <Alert severity="error">{errorMsg}</Alert>
               </div>
            ) : (
               <></>
            )}
         </div>
         <Box mt={8}>
            <Copyright />
         </Box>
      </Container>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      loggedIn: state.loggedIn,
      timezone: state.timezone,
   }
}

export default withTranslation()(
   connect(mapStateToProps, { authenticate, logout, setTimeZone })(Login)
)
