import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import TimesheetManagementRows from './components/TimesheetManagementRows'
import TimesheetManagementFilteredRows from './components/TimesheetManagementFilteredRows'
import { Tooltip } from '@mui/material'
import { fetchBankHolidays } from '../../../../../Api'
import moment from 'moment'
import TimesheetModal from '../../modal/TimesheetModal'
import { convertTimesheetHoursAndMinutes } from '../../../../../util/helpers'
import workIcon from '../../../icon/Ellipse 70.svg'
import absenceIcon from '../../../icon/Ellipse 72.svg'
import noReportIcon from '../../../icon/Ellipse 73.svg'
import weekendIcon from '../../../icon/Ellipse 74.svg'
import underTimeIcon from '../../../icon/Vector (1).svg'
import idleTimeIcon from '../../../icon/Vector (2).svg'
import overTimeIcon from '../../../icon/Vector.svg'
import holidayIcon from '../../../icon/Group 1.svg'
import checkIcon from '../../../icon/Check.svg'

const TimesheetManagementDataRows = React.memo(
   ({
      filter,
      filteredUsers,
      pageData,
      teams,
      projects,
      teamProjectFilters,
      getRelevantColourClass,
      chosenDateWeek,
      userInfo,
      t,
      tabIndex,
      newDataFetching,
   }) => {
      const [weekForModal, setWeekForModal] = useState(null)
      const [employee, setEmployee] = useState(null)
      const [open, setOpen] = useState(false)
      const [bankHoliday, setBankHoliday] = useState([])

      function calculateWorkTime(percent) {
         if (!percent) {
            return `0:00`
         } else {
            const totalHours = 8
            const minutesPerHour = 60
            const totalMinutes = totalHours * minutesPerHour

            const percentageMinutes = Math.floor((percent / 100) * totalMinutes)

            const hours = Math.floor(percentageMinutes / minutesPerHour)
            const minutes = percentageMinutes % minutesPerHour
            const paddedMinutes = String(minutes).padStart(2, '0')

            return `${hours}:${paddedMinutes}`
         }
      }
      function isTodayAfterLastWeek(week) {
         const today = moment().startOf('day')
         const lastDayOfWeek = moment(week[week.length - 1]).startOf('day')
         return today.isAfter(lastDayOfWeek)
      }

      function decimalHoursToHHMM(decimalHours) {
         let hours = Math.floor(decimalHours)
         let minutes = Math.round((decimalHours - hours) * 60)
         return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
      }

      function timeToMinutes(time) {
         const [hours, minutes] = time.split(':').map(Number)
         return hours * 60 + minutes
      }

      function minutesToHours(minutes) {
         const hours = Math.floor(minutes / 60)
         const remainingMinutes = minutes % 60
         return `${hours.toString().padStart(2, '0')}:${remainingMinutes
            .toString()
            .padStart(2, '0')}`
      }

      function checkTime(expectedTime, reportedTime) {
         const expectedMinutes = timeToMinutes(expectedTime)
         const reportedMinutes = timeToMinutes(reportedTime)
         const difference = reportedMinutes - expectedMinutes
         const cashMinutes = 48
         let tooltipText = ''
         if (difference) {
            if (reportedMinutes > expectedMinutes) {
               tooltipText = `+${minutesToHours(Math.abs(difference))}`
            } else if (reportedMinutes < expectedMinutes) {
               tooltipText = `-${minutesToHours(Math.abs(difference))}`
            } else {
               tooltipText = `${minutesToHours(Math.abs(difference))}`
            }
         } else {
            tooltipText = '00:00'
         }

         if (Math.abs(difference) <= cashMinutes) {
            return (
               <Tooltip
                  componentsProps={{
                     tooltip: {
                        sx: {
                           bgcolor: '#91D85A',
                           '& .MuiTooltip-arrow': {
                              color: '#91D85A',
                           },
                        },
                     },
                  }}
                  title={`${tooltipText}h / ${t('expectedTime')}`}
                  arrow
               >
                  <img src={checkIcon} alt="Expected Time icon" />
               </Tooltip>
            )
         } else if (difference > 0) {
            return (
               <Tooltip
                  componentsProps={{
                     tooltip: {
                        sx: {
                           bgcolor: '#A89AF9',
                           '& .MuiTooltip-arrow': {
                              color: '#A89AF9',
                           },
                        },
                     },
                  }}
                  title={`${tooltipText}h / ${t('overTime')}`}
                  arrow
               >
                  <img src={overTimeIcon} alt="Overtime icon" />
               </Tooltip>
            )
         } else {
            return (
               <Tooltip
                  componentsProps={{
                     tooltip: {
                        sx: {
                           bgcolor: '#FF505F',
                           '& .MuiTooltip-arrow': {
                              color: '#FF505F',
                           },
                        },
                     },
                  }}
                  title={`${tooltipText}h / ${t('underTime')}`}
                  arrow
               >
                  <img src={underTimeIcon} alt="Undertime icon" />
               </Tooltip>
            )
         }
      }

      const calculateProjectExpectedHours = (
         percent,
         weekSorted,
         index,
         absences,
         reportedHours,
         endDate
      ) => {
         const totalHoursPerDay = 8
         const totalHoursPer5Days = totalHoursPerDay * 5
         const week = weekSorted[index].dates
         let absentDays = 0
         absences?.forEach(absence => {
            let startDate = moment(absence.StartDate).startOf('day')
            let endDate = moment(absence.EndDate).startOf('day')

            week.forEach(date => {
               let currentDay = moment(date).startOf('day')

               if (currentDay.isSameOrAfter(startDate) && currentDay.isSameOrBefore(endDate)) {
                  absentDays += 1
               }
            })
         })

         bankHoliday?.forEach(holiday => {
            let holidayDate = moment(holiday.Date).startOf('day')

            week.forEach(date => {
               let currentDay = moment(date).startOf('day')

               if (currentDay.isSame(holidayDate)) {
                  absentDays += 1
               }
            })
         })
         if (endDate) {
            const projectEndDate = moment(endDate)
            const isWithinWeek = week.some(date => moment(date).isSame(projectEndDate, 'day'))

            if (isWithinWeek) {
               absentDays += 5 - projectEndDate.day()
            }
         }
         let expectedHours = (percent / 100) * totalHoursPer5Days
         const hoursPerDay = expectedHours / 5
         if (absentDays > 0) {
            let absentHours = hoursPerDay * absentDays
            expectedHours -= absentHours
         }
         const expectedTimeReport = decimalHoursToHHMM(expectedHours)
         return checkTime(expectedTimeReport, reportedHours)
      }

      useEffect(() => {
         fetchBankHolidays(userInfo)
            .then(res => {
               setBankHoliday(res)
            })
            .catch(() => console.log('Error fetching holidays.'))
      }, [userInfo])
      const handleClose = () => {
         setOpen(false)
         setWeekForModal(null)
      }
      const stripeRowCheck = useCallback(userIndex => {
         return userIndex % 2 === 0
      }, [])

      const isDateAHoliday = date => {
         return bankHoliday.find(holiday => {
            const date1 = moment(date).format('YYYY-MM-DD')

            const date2 = moment(holiday.Date).format('YYYY-MM-DD')

            return date1 === date2
         })
      }

      const openModal = (person, week) => {
         setOpen(true)
         setEmployee(person)
         setWeekForModal(week)
      }

      const isHaveReports = useCallback((person, week) => {
         const weekDays = week.dates.map(date => new Date(date).getDate())
         const reports = person.projectReports.some(project => {
            if (project.reportedDates.length > 0) {
               const isInRange = project.reportedDates.some(report =>
                  weekDays.includes(new Date(report.Date).getDate())
               )
               return isInRange
            }
         })
         return reports
      }, [])

      const takeTime = useMemo(
         () => time => {
            const minutesFromTime = time.toString().split('.')[1]

            if (Number(minutesFromTime) > 59) {
               const hours = Math.floor(time)
               const minutes = Math.round((time - hours) * 60)
               return parseFloat(hours.toString() + '.' + minutes.toString())
            } else {
               return time
            }
         },
         []
      )

      const isInRange = useMemo(() => {
         return (reportedHours, expectedHours) => {
            const expectedHoursString = expectedHours.toString().split('.')
            const reportedHoursString = reportedHours.toString().split('.')
            const expectedHoursToMinutes =
               Number(expectedHoursString[0]) * 60 + Number(expectedHoursString[1])
            const reportedHoursToMinutes =
               Number(reportedHoursString[0]) * 60 + Number(reportedHoursString[1])
            if (
               reportedHoursToMinutes >= expectedHoursToMinutes - 24 &&
               reportedHoursToMinutes <= expectedHoursToMinutes + 24
            ) {
               return true
            } else {
               return false
            }
         }
      }, [])

      function formatTime(time) {
         let [hours, minutes] = String(time).split('.')
         return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`
      }

      const getDayReport = (weekDate, project, absence) => {
         const foundHoliday = isDateAHoliday(weekDate)
         const expectedHours = (Number(project.workPercent) * 8) / 100
         const decimalHours = takeTime(expectedHours.toFixed(2))
         const iconMapper = {
            noReport: {
               imgUrl: noReportIcon,
               tooltipColor: 'transparent',
               title: '',
            },
            overTime: {
               imgUrl: overTimeIcon,
               tooltipColor: '#A89AF9',
               title: hours => `${formatTime(hours)}${t('h')} / ${t('overTime')} `,
            },
            work: {
               imgUrl: workIcon,
               tooltipColor: '#91D85A',
               title: hours => `${formatTime(hours)}${t('h')} / ${t('expectedTime')}`,
            },
            idleTime: {
               imgUrl: idleTimeIcon,
               tooltipColor: '#FF505F',
               title: () => t('idleTime'),
            },
            underTime: {
               imgUrl: underTimeIcon,
               tooltipColor: '#FF505F',
               title: hours => `${formatTime(hours)}${t('h')} / ${t('underTime')}`,
            },
            holiday: {
               imgUrl: holidayIcon,
               tooltipColor: '#8ACCE6',
               title: name => name,
            },
            weekend: {
               imgUrl: weekendIcon,
               tooltipColor: '#8ACCE6',
               title: () => t('timesheetTooltipMessageWeekend'),
            },
            absence: {
               imgUrl: absenceIcon,
               tooltipColor: '#8ACCE6',
               title: absenceType => t(absenceType),
            },
         }
         let reportObj = iconMapper['noReport']

         if (project.reportedDates.length > 0) {
            for (let report of project.reportedDates) {
               let totalHoursFromReports = 0
               const findReports = project?.reportedDates.filter(
                  element => new Date(element.Date).getDate() === new Date(report.Date).getDate()
               )

               if (findReports.length > 1) {
                  let totalMinutes = 0

                  findReports.forEach(report => {
                     const [hours, minutes] = report.ReportedHours.split(':')
                     totalMinutes += Number(hours) * 60 + Number(minutes)
                  })

                  const hours = Math.floor(totalMinutes / 60)
                  const minutes = totalMinutes % 60

                  totalHoursFromReports = `${hours.toString()}.${minutes
                     .toString()
                     .padStart(2, '0')}`
               } else {
                  totalHoursFromReports +=
                     Number(report.ReportedHours.split(':')[0]) +
                     Number(report.ReportedHours.split(':')[1]) / 100
                  totalHoursFromReports = totalHoursFromReports.toFixed(2)
               }

               if (new Date(report.Date).getDate() === weekDate.getDate()) {
                  if (new Date(weekDate).getDay() === 6 || new Date(weekDate).getDay() === 0) {
                     reportObj = iconMapper['overTime']
                     reportObj.title = iconMapper['overTime'].title(totalHoursFromReports)
                     return reportObj
                  }
                  if (report.WorkType === 'A2') {
                     return null
                  }
                  if (isInRange(totalHoursFromReports, decimalHours)) {
                     if (foundHoliday) {
                        reportObj = iconMapper['overTime']
                        reportObj.title = iconMapper['overTime'].title(totalHoursFromReports)
                        return reportObj
                     }
                     if (findReports.length > 1) {
                        if (findReports.some(report => report.WorkType === 'A2')) {
                           return null
                        }
                     }
                     if (report.WorkType === 'A2') {
                        return null
                     }
                     reportObj = iconMapper['work']
                     reportObj.title = iconMapper['work'].title(totalHoursFromReports)
                     return reportObj
                  }
                  if (report.WorkType === 'A2') {
                     if (findReports.length > 1) {
                        if (findReports.some(report => report.WorkType !== 'A2')) {
                           return null
                        }
                     }
                     reportObj = iconMapper['idleTime']
                     reportObj = iconMapper['idleTime'].title()
                     return reportObj
                  }
                  if (
                     decimalHours < +totalHoursFromReports ||
                     decimalHours > +totalHoursFromReports
                  ) {
                     if (decimalHours < +totalHoursFromReports) {
                        reportObj = iconMapper['overTime']
                        reportObj.title = iconMapper['overTime'].title(totalHoursFromReports)
                     }
                     if (decimalHours > +totalHoursFromReports) {
                        reportObj = iconMapper['underTime']
                        reportObj.title = iconMapper['underTime'].title(totalHoursFromReports)
                     }
                     if (foundHoliday) {
                        reportObj = iconMapper['overTime']
                     }
                     if (findReports.length > 1) {
                        if (findReports.some(report => report.WorkType === 'A2')) {
                           return null
                        }
                     }

                     return reportObj
                  }
               }
            }
         }

         if (foundHoliday) {
            reportObj = iconMapper['holiday']
            reportObj.title = iconMapper['holiday'].title(foundHoliday.Name)
            return reportObj
         }
         if (new Date(weekDate).getDay() === 6 || new Date(weekDate).getDay() === 0) {
            reportObj = iconMapper['weekend']
            reportObj.title = iconMapper['weekend'].title()
            return reportObj
         }
         if (absence) {
            for (let element of absence) {
               if (
                  new Date(element.StartDate).getDate() <= new Date(weekDate).getDate() &&
                  new Date(weekDate).getDate() <= new Date(element.EndDate).getDate()
               ) {
                  reportObj = iconMapper['absence']
                  reportObj.title = iconMapper['absence'].title(element.AbsenceType)
                  return reportObj
               }
            }
         }

         return reportObj
      }

      const reportDay = useCallback(
         (weekDate, project, absence) => {
            const dayReport = getDayReport(weekDate, project, absence)

            if (dayReport) {
               return (
                  <Tooltip
                     componentsProps={{
                        tooltip: {
                           sx: {
                              bgcolor: dayReport.tooltipColor,
                              '& .MuiTooltip-arrow': {
                                 color: dayReport.tooltipColor,
                              },
                           },
                        },
                     }}
                     title={dayReport.title}
                     arrow
                  >
                     <img src={dayReport.imgUrl} alt="Day icon" />
                  </Tooltip>
               )
            } else {
               return (
                  <Tooltip
                     componentsProps={{
                        tooltip: {
                           sx: {
                              bgcolor: '#FF505F',
                              '& .MuiTooltip-arrow': {
                                 color: '#FF505F',
                              },
                           },
                        },
                     }}
                     title={t('idleTime')}
                     arrow
                  >
                     <img src={idleTimeIcon} alt=" Idle time icon" />
                  </Tooltip>
               )
            }
         },
         [getDayReport]
      )

      const calculateProjectActualHours = useCallback(
         (reportedDates, weekSorted, index) => {
            let projectHours = 0
            let projectMinutes = 0
            const week = weekSorted[index].dates.map(date => new Date(date).getDate())

            reportedDates.forEach(reportedDate => {
               if (week.includes(new Date(reportedDate.Date).getDate())) {
                  projectHours += Number(reportedDate.ReportedHours.split(':')[0]) || 0
                  projectMinutes += Number(reportedDate.ReportedHours.split(':')[1]) || 0
               }
            })

            return convertTimesheetHoursAndMinutes(projectHours, projectMinutes)
         },
         [convertTimesheetHoursAndMinutes]
      )

      const rowsProps = {
         filteredUsers,
         teams,
         projects,
         stripeRowCheck,
         reportDay,
         teamProjectFilters,
         filter,
         chosenDateWeek,
         getRelevantColourClass,
         openModal,
         isHaveReports,
         calculateProjectActualHours,
         pageData,
         calculateProjectExpectedHours,
         isTodayAfterLastWeek,
         calculateWorkTime,
         tabIndex,
         newDataFetching,
      }

      return (
         <>
            {teamProjectFilters.length !== 0 ? (
               <TimesheetManagementFilteredRows {...rowsProps} />
            ) : (
               <TimesheetManagementRows {...rowsProps} />
            )}
            {!!weekForModal && (
               <TimesheetModal
                  open={open}
                  week={weekForModal}
                  employee={employee}
                  handleClose={handleClose}
               />
            )}
         </>
      )
   }
)

const mapStateToProps = state => {
   return {
      state,
      userInfo: state.userInfo,
   }
}

export default withTranslation()(connect(mapStateToProps)(TimesheetManagementDataRows))
