import { useEffect } from 'react'
import {
   getWeeks,
   setDateToEndOfWorkDay,
   dayIntervalContainsDate,
   dayIntervalContainsDateExludingStartEndDay,
} from 'novarto-time'
import moment from 'moment-timezone'

export const useEffectOnlyOnce = func => useEffect(func, [])

export const getUserByEmployeeID = (EmployeeID, users) => {
   const user = users.filter(u => {
      return EmployeeID === u.EmployeeID
   })
   return user[0]
}

export const employmentIsMissingCostCenter = (Employment, CostCenters) => {
   return CostCenters.some(cc => Employment.CostCenterID === cc.ID)
}

export const getEmploymentsForDate = (Employments, d) => {
   const theDate = moment(new Date(d))

   if (Employments == null) {
      return null
   }

   const result = Employments.filter(employment => {
      const employmentStartDate = moment(employment.StartDate)
      const employmentEndDate = employment.EndDate ? moment(employment.EndDate) : null

      return (
         (employmentStartDate?.year() === theDate.year() &&
            employmentEndDate?.year() === theDate.year()) ||
         (employmentStartDate.isBefore(theDate) &&
            (employmentEndDate === null || employmentEndDate.isAfter(theDate)))
      )
   })

   if (result != null && result.length > 0) {
      return result
   }

   return null // Nothing found
}

export const getEmploymentsForSpan = (Employments, span) => {
   if (!Employments) {
      return null
   }

   const spanStart = moment(span.Start)
   const spanEnd = moment(span.End)

   const result = Employments.filter(e => {
      const eStart = moment(e.StartDate)
      const eEnd = e.EndDate ? moment(e.EndDate) : null

      // If employment start is before span end and employment end either is after span start or doesn't exist, the employment and span overlap
      return eStart.isBefore(spanEnd) && (eEnd === null || eEnd.isAfter(spanStart))
   })

   if (result.length > 0) {
      return result
   } else {
      return null
   }
}

export const getVaccationDaysForDate = (Employments, date) => {
   // Get the employments for this date... calculate days...
   const employmentsForDate = getEmploymentsForDate(Employments, date)
   if (!employmentsForDate || employmentsForDate.length < 0) {
      return 0
   }
   let vacations = 0
   employmentsForDate.forEach(employment => {
      if (employment.VacationDays !== null) {
         vacations += employment.VacationDays
      }
   })

   return vacations
}

/**
 * Check array of employments for the current employment. It should be the first
 * employment that matches today date.
 * @param {} Employments
 */
export const getCurrentEmployment = (Employments, WorkDay) => {
   if (Employments == null) {
      return null
   }
   const result = Employments.filter(Employment => {
      const idag = new Date()
      const start = new Date(Employment.StartDate)
      let end = Employment.EndDate != null ? new Date(Employment.EndDate) : null
      if (end != null) {
         // Set to end of the day
         end = new Date(setDateToEndOfWorkDay(end, WorkDay))
      }

      // Idag ligger inom intervallet
      if (start <= idag && end >= idag) {
         return Employment
      }

      // Idag ligger efter startdatum, och slutdatum är öppet (null)
      if (start <= idag && end == null) {
         return Employment
      }

      return false
   })

   if (result != null && result.length > 0) {
      return result
   }

   return null // Nothing found
}

/**
 * Check if an employment has changed during the report period.
 * 1. If StartDate is during the period
 * 2. If EndDate is during the period
 * @param {*} Employment
 * @param {*} Report
 */
export const employmentChangedDuringReportPeriod = (Employment, Report) => {
   if (Employment.StartDate >= Report.StartDate && Employment.StartDate <= Report.EndDate) {
      return true
   }
   if (Employment.EndDate != null) {
      if (Employment.EndDate >= Report.StartDate && Employment.EndDate <= Report.EndDate) {
         return true
      }
   }
   return false
}

/**
 * Checks if Employment has valid dates for existing Employments array.
 * Dates are NOT valid if:
 * 1. EndDate is before StartDate
 * 2. EndDate is null and an existing null EndDate exists in array.
 * 3. StartDate cannot be null.
 * 4. Datumintervallet i Employment cannot be in any interval in the array
 * @param {} Employments
 * @param {} Employment
 */
export const validateEmploymentDates = (Employments, Employment) => {
   if (Employment.StartDate == null) {
      return { success: false, message: 'Startdatum måste anges för en anställning.' }
   }
   if (Employment.EndDate != null && Employment.EndDate < Employment.StartDate) {
      return { success: false, message: 'Slutdatum kan inte vara före startdatum.' }
   }

   /* TODO fix this validation for updates...
  if (Employment.EndDate == null){
    const result = Employments.filter(e => e.EndDate == null);
    if (result.length > 0){
        return {success:false, message:"Det kan endast finnas en anställning med öppet slutdatum."}
    }
  }
  */

   // TODO check for 4.

   return { success: true, message: 'validated success' } // Valid dates
}

/**
 * Sort method for any collection of objects containing a label
 */
export const sortByLabel = (s1, s2) =>
   s1.label.toLowerCase() === s2.label.toLowerCase()
      ? 0
      : s1.label.toLowerCase() < s2.label.toLowerCase()
        ? -1
        : 1

export const sortByNickThenLabel = (s1, s2) =>
   `${s1?.nick}${s1.label.toLowerCase()}` === `${s2?.nick}${s2.label.toLowerCase()}`
      ? 0
      : `${s1?.nick}${s1.label.toLowerCase()}` < `${s2?.nick}${s2.label.toLowerCase()}`
        ? -1
        : 1

export const sortAsc = (s1, s2) => (s1 === s2 ? 0 : s1 < s2 ? -1 : 1)
export const sortDesc = (s1, s2) => (s1 === s2 ? 0 : s1 < s2 ? 1 : -1)

/**
 * Sort method for any collection of objects containing StartDate
 */
export const sortByStartDate = (s1, s2) =>
   s1.StartDate === s2.StartDate ? 0 : s1.StartDate < s2.StartDate ? -1 : 1

/**
 * Sort method for any collection of objects containing EndDate
 */
export const sortByEndDate = (s1, s2) =>
   s1.EndDate === s2.EndDate ? 0 : s1.EndDate < s2.EndDate ? -1 : 1

export const sortByComparison = (o1, o2) => (o1 === o2 ? 0 : o1 < o2 ? -1 : 1)

export const highlightDaysInRed = days => {
   return [
      {
         'react-datepicker__day--highlighted-custom-1': days.map(day => new Date(day.Date)),
      },
   ]
}

export const filterProjectsByStartAndEndDate = (projects, userId) => {
   let userProjects = []

   // filter projects by project StartDate and project EndDate
   userProjects = projects
      .filter(project => project.MemberIDs.includes(userId))
      .filter(
         project =>
            project.Status === 'Active' &&
            ((new Date(project.StartDate) < new Date() && new Date(project.EndDate) > new Date()) ||
               (!project.StartDate && !project.EndDate) ||
               (!project.StartDate && new Date(project.EndDate) > new Date()) ||
               (!project.EndDate && new Date(project.StartDate) < new Date()))
      )

   // filter projects by user StartDate/EndDate in the project
   userProjects = userProjects
      .map(({ MemberDetails, ...others }) => {
         const filteredMembers = MemberDetails.filter(
            member => member._id === userId && member.isActive
         )
         return { filteredMembers, ...others }
      })
      .filter(project => project.filteredMembers.length > 0)

   return userProjects
}

export const getAllDatesOfAWeek = (startDate, numberOfWeeks) => {
   const getWeek = date => {
      return moment(date).isoWeek()
   }

   const currDate = startDate
   const today = currDate.getDay()

   let days = []
   let weeks = []
   let sortedWeeks = []
   currDate.setDate(currDate.getDate() - (today === 0 ? 7 : today))
   const newWeeks = getWeeks(new Date(currDate), numberOfWeeks)

   for (let w = 0; w < numberOfWeeks; w++) {
      let sortedDates = []
      for (let i = 0; i < 7; i++) {
         currDate.setDate(currDate.getDate() + 1)
         days.push(new Date(currDate))
         sortedDates.push(new Date(currDate))
      }
      sortedWeeks.push({
         number: newWeeks.weekNumber[w],
         dates: sortedDates,
      })
      weeks.push(getWeek(currDate))
   }

   return { weekDates: days, weekNumber: weeks, weeksSorted: sortedWeeks }
}

export const convertTimesheetHoursAndMinutes = (hours, minutes) => {
   let hoursToAdd = 0
   if (minutes === 60) {
      hoursToAdd++
      minutes = 0
   } else if (minutes > 60) {
      hoursToAdd = Math.floor(minutes / 60)
      minutes = minutes % 60
   }

   hours += hoursToAdd

   return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`
}

export const isTimeMoreThan24h = (
   timeReported,
   reportedTimeForThisDay,
   previouslyReportedTime = null
) => {
   let hoursForThisDay = 0
   let minutesForThisDay = 0
   const reportedTimeForThisDayHours = Number(reportedTimeForThisDay.split(':')[0]) || 0
   const reportedTimeForThisDayMinutes = Number(reportedTimeForThisDay.split(':')[1]) || 0
   if (previouslyReportedTime) {
      const previouslyReportedTimeHours = Number(previouslyReportedTime.split(':')[0]) || 0
      const previouslyReportedTimeMinutes = Number(previouslyReportedTime.split(':')[1]) || 0
      let hoursToSubtract = 0
      minutesForThisDay = reportedTimeForThisDayMinutes - previouslyReportedTimeMinutes
      if (minutesForThisDay < 0) {
         hoursToSubtract++
         minutesForThisDay = 60 - minutesForThisDay
      }

      hoursForThisDay = reportedTimeForThisDayHours - previouslyReportedTimeHours
   } else {
      hoursForThisDay = reportedTimeForThisDayHours
      minutesForThisDay = reportedTimeForThisDayMinutes
   }

   const timeReportedHours = Number(timeReported.split(':')[0]) || 0
   const timeReportedMinutes = Number(timeReported.split(':')[1]) || 0

   let hoursToAdd = 0

   minutesForThisDay += timeReportedMinutes

   if (minutesForThisDay === 60) {
      hoursToAdd++
      minutesForThisDay = 0
   } else if (minutesForThisDay > 60) {
      hoursToAdd = Math.floor(minutesForThisDay / 60)
      minutesForThisDay = minutesForThisDay % 60
   }

   hoursForThisDay += hoursToAdd + timeReportedHours

   return hoursForThisDay > 24 || (hoursForThisDay === 24 && minutesForThisDay > 0)
}

export const convertTimeOnBlur = timeToConvert => {
   let generatedHours = ''
   let generatedMinutes = ''
   const hours = Number(timeToConvert.split(':')[0])
   const minutes = Number(timeToConvert.split(':')[1])

   if (hours < 10) {
      generatedHours = `0${hours}`
   } else {
      generatedHours = `${hours}`
   }
   if (!minutes) {
      generatedMinutes = `00`
   } else if (minutes < 10) {
      generatedMinutes = `0${minutes}`
   } else {
      generatedMinutes = `${minutes}`
   }

   return `${generatedHours}:${generatedMinutes}`
}

export const absencePeriodDateFormatter = selectedWeeks => {
   const startDate = new Date(selectedWeeks?.weekDates[0].getFullYear(), 0, 1)
   const endDate = new Date(new Date(startDate.getFullYear() + 1, 2, 1))

   return { startDate, endDate }
}

export function sortByProjectType(a, b) {
   if (a.projectType === b.projectType) return 0
   if (a.projectType === 'External') return -1
   return 1
}

export function formatDate(updateDate) {
   if (updateDate !== null) {
      const date = new Date(updateDate)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()

      return `${day}.${month}.${year}`
   } else {
      return 'N/A'
   }
}
export function isUpdated(date) {
   if (!date) {
      return false
   }
   if (date) {
      const lastUpdated = new Date(date)
      const today = new Date()

      const timeDifference = today - lastUpdated

      const differenceInDays = timeDifference / (1000 * 60 * 60 * 24)

      if (differenceInDays >= 14) {
         return false
      } else {
         return true
      }
   }
}
export const checkForNonWorkingOrSubtaskHours = reportedDates => {
   return reportedDates.some(reportedDate => {
      if (reportedDate.WorkType !== 'A1') {
         const [hours, minutes] = reportedDate.ReportedHours.split(':').map(Number)
         const totalMinutes = hours * 60 + minutes
         return totalMinutes > 0
      }

      return false
   })
}

export const formatNumber = entry => {
   return Number(Math.ceil(entry))
}

export const totalHours = hours => {
   const [hour, minute] = hours.split(':').map(Number)
   const totalHours = hour + minute / 60

   return Math.ceil(totalHours)
}

export const isThisYear = year => year === new Date().getFullYear()

export const getMonthsBetween = (startDate, endDate) => {
   let monthCodes = []
   let monthNames = []
   let startMoment = moment(startDate)
   let endMoment = moment(endDate)

   while (startMoment.isSameOrBefore(endMoment, 'month')) {
      monthCodes.push(startMoment.format('YYYY-MM'))
      monthNames.push(startMoment.format('MMM YY'))
      startMoment.add(1, 'month')
   }

   return { monthCodes, monthNames }
}
export const getYearsBetween = (startDate, endDate) => {
   let years = []
   let startMoment = moment(startDate)
   let endMoment = moment(endDate)

   while (startMoment.isSameOrBefore(endMoment, 'year')) {
      years.push(startMoment.format('YYYY'))
      startMoment.add(1, 'year')
   }

   return years
}
export const getQuartersBetween = (startDate, endDate) => {
   const startYear = startDate.getFullYear()
   const endYear = endDate.getFullYear()
   const startQuarter = Math.ceil((startDate.getMonth() + 1) / 3)
   const endQuarter = Math.ceil((endDate.getMonth() + 1) / 3)

   const quarters = []
   for (let year = startYear; year <= endYear; year++) {
      const firstQuarter = year === startYear ? startQuarter : 1
      const lastQuarter = year === endYear ? endQuarter : 4
      for (let quarter = firstQuarter; quarter <= lastQuarter; quarter++) {
         quarters.push(`${year} Q${quarter}`)
      }
   }
   return quarters
}
